

export const data = {
    description : "Aspiring magician",
    project : [
        {
            src: "https://scriberx.rishabhjha.com/search",
            img_src: "./images/scriberx.jpg",
            desc: "ScribeRX",
            alt: "image"  
        },
        {
            src: "https://query.rishabhjha.com",
            img_src: "./images/yaksha.png",
            desc: "Project Yaksha",
            alt: "image"  
        },
        {
            src: "https://pypi.org/project/tf-osqp/",
            img_src: "./images/grad3.png",
            desc: "TF-OSQP",
            alt: "image"  
        },

        {
            src: "https://github.com/rishabhjha33",
            img_src: "./images/github.png",
            desc: "GitHub",
            alt: "image"  
        },
        {
            src: "/about",
            img_src: "./images/self.jpg",
            desc: "About me",
            alt: "image"  
        },
        {
            src: "/#contact",
            img_src: "./images/connect.png",
            desc: "Contact",
            alt: "image"  
        }
    ],
    email: "",
    mob: "",
    linkedin: "https://www.linkedin.com/in/rishabhjha33/",
    instagram: "https://www.rishabhjha.com",
    facebook: "https://www.rishabhjha.com",
    github: "https://github.com/rishabhjha33",

    profileHeading: " कर्मण्येवाधिकारस्ते मा फलेषु कदाचन ।",
    aboutMe: "Building mission driven teams that shape the destiny of their organizations and the world.🚀 <br/> <br/> \
        My expertise is in enterprise digital transformation, software product management and AI for healthcare. \
        Through empathetic leadership, I seek to empower my teams to bring their fullest selves to work.",
    dp_src: "./images/LinkedIn24.jpg",
    age: "27",
    location: "New Delhi, India",
    experiencesHeading: "Fortunate to have learnt from the best",
    careers: [
        {
            name: "Bain & Co.",
            duration: "July 2018 - Present",
            descHeading: "Project Leader",
            desc: "Managing AI products for Bain"
        },
        {
            name: "Directi",
            duration: "May-July 2017",
            descHeading: "Strategy and BD intern",
            desc: "Designed market entry strategy for CodeChef's Certification Program"
        },
        {
            name: "XLRI Jamshedpur",
            duration: "December 2016",
            descHeading: "Marketing intern",
            desc: "Researched implementations of ML in marketing"
        },
        {
            name: "IIM Ahmedabad",
            duration: "December 2015",
            descHeading: "Public Policy intern",
            desc: "Coordinated surveys to asses implementation of RTE"
        }
    ],
    education: [
        {
            name: "IIT Roorkee",
            duration: "July 2014 - May 2021",
            descHeading: "Materials and Metallurgical Engineering",
            desc: "Led the Debating Society; Vice President of Consulting Club"
        },
        {
            name: "Loyola School, Jamshedpur",
            duration: "2001 - 2014",
            descHeading: "Computer Science",
            desc: "President of Debating Club; Active quizzer"
        },
    ],
    abilitiesHeading: "Jack of many trades, master of a few",
    skillsLeft: [
        {
            name: "Product management",
            value: 5
        },
        {
            name: "Vendor management",
            value: 5
        },
    ],
    skillsRight: [
        {
            name: "Language Technology",
            value: 5
        },
        {
            name: "Agile development",
            value: 5
        }
    ],
    languagesLeft: [
        {
            name: "English",
            value: 5
        }
    ],
    languagesRight: [
        {
            name: "Hindi",
            value: 5
        }
    ],
    toolsLeft: [
        {
            name: "TensorFlow",
            value: 5
        },
        {
            name: "PyTorch",
            value: 4
        }
    ],
    toolsRight: [
        {
            name: "Google Cloud",
            value: 5
        },
        {
            name: "Python",
            value: 5
        }
    ]

}
import React, { Component } from 'react';
import './App.css';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LandingPage0424 } from './components/LandingPage0424.js';
import { LandingPage } from './components/LandingPage.js';
import {About} from './components/About.js';
import {Blog} from './components/Blog.js';

export class App extends Component {
  render() {
    return (
      <Router>

        <Route exact path = "/" render = {()=> <LandingPage0424 /> }/>
        <Route exact path="/products" render={() => <LandingPage />} />
        <Route exact path = "/about" render = {()=> <About/> }/>
        <Route exact path = "/blog" render = {()=> <Blog/> }/>

      </Router>
    )
  }
}

export default App

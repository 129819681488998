import React, { Component } from 'react';
import './LandingPage0424.css';

import {data} from "./data.js";

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import Rating from '@material-ui/lab/Rating';

export class LandingPage0424 extends Component {

    render() {

        return (
            <div className="mainlanding0424">
                <div className="landingintro">
                    <p>Rishabh Jha
                    <h4>Aspiring Magician 🪄 </h4>
                    </p>
                    <br></br>


                    <div className="welcomeInfo">

                        <div className="hello">
                            

                            <p>
                                I'm Rishabh Jha.
                                <br /><br />
                                I love airplanes, history and building happy teams that enjoy overachieving.
                                <br /><br />
                                <a href="https://www.linkedin.com/in/rishabhjha33/">LinkedIn</a> is the easiest place to reach me.
                            </p>
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>

            </div>
        )
    }
}

export default LandingPage0424;

import React, { Component } from 'react'

//import './Blog.css';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {data} from "./data.js";
import Rating from '@material-ui/lab/Rating';
import GitHubIcon from '@material-ui/icons/GitHub';


export class Blog extends Component {
    render() {
        return (
            <div className="main">
                <div className="profile">
                    <p>Profile</p>
                    <h4>{data.profileHeading}</h4>
                    
                    <br></br>
                    
                    <hr></hr>

                    <div className="basicInfo">
                        <div className="aboutMe">
                            <h3>About Me</h3>
                            <p>
                                {data.aboutMe}
                            </p>
                        </div>
                        <div className="dp"><img src={require("" + data.dp_src).default}/></div>
                        <div className="details">
                            <h3>Details</h3>
                            Name:
                            <p>Rishabh Jha</p>
                            Age:
                            <p>{data.age} Years</p>
                            Location:
                            <p>{data.location}</p>
                            <button onClick={()=>window.open(data.linkedin, "_blank")}> <LinkedInIcon fontSize="small"/>LinkedIn</button>
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>

                <div className="experiences">
                    
                    <br></br>
                    <br></br>

                    <p>Experience</p>
                    <h4>{data.experiencesHeading}</h4>
                     
                    <br></br>
                    
                    
                    <div className="section">
                        <hr></hr>
                        <div className="sectionHeading">
                            <h3>Careers</h3>
                        </div>

                        {data.careers.map((items)=>{
                            return(
                                <div className="sectionDetails">
                                    <div className="leftDetails">
                                        <strong>{items.name}</strong>
                                        <p>{items.duration}</p>
                                    </div>
                                    <div className="rightDetails">
                                        <strong>{items.descHeading}</strong>
                                        <p>{items.desc}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="section">
                        <hr></hr>
                        <div className="sectionHeading">
                            <h3>Education</h3>
                        </div>

                        {data.education.map((items)=>{
                            return(
                                <div className="sectionDetails">
                                    <div className="leftDetails">
                                        <strong>{items.name}</strong>
                                        <p>{items.duration}</p>
                                    </div>
                                    <div className="rightDetails">
                                        <strong>{items.descHeading}</strong>
                                        <p>{items.desc}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <br></br>

                </div>

                <div className="experiences" style={{backgroundColor: 'white'}}>
                    <br></br>
                    <p>Abilities</p>
                    <h4>{data.abilitiesHeading}</h4>
                     
                    <br></br>

                    <div className="section">
                        <hr></hr>
                        <div className="sectionHeading">
                            <h3>Skills</h3>
                        </div>
                        
                        <div className="sectionDetails">
                            <div className="leftDetails">
                                {data.skillsLeft.map((items)=>{
                                    return(
                                        <div className="tile">
                                            <p>{items.name}</p>
                                            <span> <Rating size="small" value={items.value} readOnly /> </span>
                                        </div>
                                    )
                                })}
                                
                            </div>
                            <div className="rightDetails">
                                {data.skillsRight.map((items)=>{
                                    return(
                                        <div className="tile">
                                            <p>{items.name}</p>
                                            <span> <Rating size="small" value={items.value} readOnly /> </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <hr></hr>
                        <div className="sectionHeading">
                            <h3>Languages</h3>
                        </div>

                        <div className="sectionDetails">
                            <div className="leftDetails">
                                {data.languagesLeft.map((items)=>{
                                    return(
                                        <div className="tile">
                                            <p>{items.name}</p>
                                            <span> <Rating size="small" value={items.value} readOnly /> </span>
                                        </div>
                                    )
                                })}
                                
                            </div>
                            <div className="rightDetails">
                                {data.languagesRight.map((items)=>{
                                    return(
                                        <div className="tile">
                                            <p>{items.name}</p>
                                            <span> <Rating size="small" value={items.value} readOnly /> </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <hr></hr>
                        <div className="sectionHeading">
                            <h3>Tools</h3>
                        </div>

                        <div className="sectionDetails">
                            <div className="leftDetails">
                                {data.toolsLeft.map((items)=>{
                                    return(
                                        <div className="tile">
                                            <p>{items.name}</p>
                                            <span> <Rating size="small" value={items.value} readOnly /> </span>
                                        </div>
                                    )
                                })}
                                
                            </div>
                            <div className="rightDetails">
                                {data.toolsRight.map((items)=>{
                                    return(
                                        <div className="tile">
                                            <p>{items.name}</p>
                                            <span> <Rating size="small" value={items.value} readOnly /> </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    
                    <br></br>
                    <br></br>

                </div>

                <div className="footer1" id="contact">
                    <div className="footerInfo1">
                        <div> {data.email} </div>
                        <div> {data.mob} </div>
                    </div>
                    <div className="footerIcons1">
                        <a href={data.linkedin} target="_blank">
                          <LinkedInIcon fontSize="large" style={{color: "#0077b5"}} />
                        </a>
                        <a href={data.github} target="_blank">
                          <GitHubIcon fontSize="large" style={{color: "#eeeeee"}} />
                        </a>
                    </div>
                </div>

            </div>
        )
    }
}

export default Blog

import React, { Component } from 'react';
import './LandingPage.css';

import {data} from "./data.js";

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

export class LandingPage extends Component {

    render() {
        let helpArr=[];
        for(let i=0; i < Math.floor(data.project.length/3) ; i++){
            helpArr.push(
                <div className="projectRow">
                    {data.project.slice(3*i, 3*i+3).map((items)=>{
                        return(
                <div className="projectColumn" onClick={()=>window.open(items.src, "_blank")}>
                    <img src={require("" + items.img_src).default} alt={items.alt} className="image"/>
                    <div className="fade">
                    <div className="text">
                            {items.desc}
                        </div>
                    </div>
                </div>)
            })}
                </div>
            );
        }

        let images = helpArr.map((items)=>{
            return(items)
        })

        return (
            <div className="main">
                <div className="header">
                    <ul>
                        <li className="navTitle"><h2>Rishabh Jha</h2></li>
                        <li className="liItem" ><a href="#contact">Contact</a></li>
                        <li className="liItem" ><a href="/about">About</a></li>
                        <li className="liItem" ><a href="/blog">Blog</a></li>
                    </ul>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div className="descriptionBox">
                    <p className="description"> {data.description} </p>
                    <p> .</p>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                {images}

                <div className="footer1" id="contact">
                    <div className="footerInfo1">
                        <div> {data.email} </div>
                        <div> {data.mob} </div>
                    </div>
                    <div className="footerIcons1">
                        <a href={data.linkedin} target="_blank">
                          <LinkedInIcon fontSize="large" style={{color: "#0077b5"}} />
                        </a>
                        <a href={data.github} target="_blank">
                          <GitHubIcon fontSize="large" style={{color: "#eeeeee"}} />
                        </a>
                    </div>
                </div>

            </div>
        )
    }
}

export default LandingPage
